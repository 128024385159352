var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.controller.isLoading)?_c('LoadingOverlay'):_vm._e(),(_vm.showUploadModal)?_c('modal-upload-bulky',{ref:"modal-upload-bulky",on:{"close-upload-modal":function () {
        _vm.showUploadModal = false
      }}}):_vm._e(),_c('div',{staticClass:"flex flex-col"},[_c('Card',{ref:"download",staticClass:"mb-6",attrs:{"id":"download-template-upload-topup","title":"Fitur Baru: Adjust Saldo dengan Upload Bulky!","description":"Sekarang Anda bisa upload bulky untuk melakukan adjust saldo pada user dalam 1 file excel. Download templatenya sekarang!","primaryText":"Download Template","useSingleButton":true},on:{"action":_vm.onDownloadTemplate}},[_c('IllustrationCreateFaq',{staticClass:"mr-6"})],1),_c('div',{staticClass:"flex gap-x-5 justify-between w-full"},[_c('div',{staticClass:"w-full"},[_c('h2',{staticClass:"text-sm font-semibold text-bgMatterhorn mb-2"},[_vm._v("Search")]),_c('TextInput',{ref:"input-search",attrs:{"placeholder":"Search by Phone Number","type":"search"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return (function () {
              _vm.fetchRequestTopupList(true)
            })($event)}},model:{value:(_vm.parameters.phoneNumber),callback:function ($$v) {_vm.$set(_vm.parameters, "phoneNumber", $$v)},expression:"parameters.phoneNumber"}})],1),_c('div',{staticClass:"flex items-end wrapper-button-upload-bulk"},[_c('Button',{ref:"button-upload-bulky",staticClass:"py-2.5 px-4 text-xs font-semibold",attrs:{"customPadding":"","buttonStyle":"outline"},on:{"click":function($event){return _vm.actionModalUpload()}}},[_vm._v(" Upload Bulky ")])],1)])],1),_c('div',{staticClass:"mt-6"},[_c('DataTable',{attrs:{"isLoading":_vm.controller.isLoading,"headers":_vm.headers,"data":_vm.dataRequestTopList,"baseLink":"/adjustment/","showViewAction":true},scopedSlots:_vm._u([{key:"action",fn:function(ref){
            var data = ref.data;
return [_c('Button',{staticClass:"py-2.5 px-10 text-xs font-semibold",attrs:{"customPadding":"","disabled":!_vm.isSuperAdmin},on:{"click":function($event){return _vm.$router.push(("/adjustment/request-topup/" + (data[0])))}}},[_vm._v(" Request ")])]}},{key:"data-empty",fn:function(){return [_c('EmptyState',{attrs:{"type":"proof-delivery","text":"Data Not Found","description":"Request top-up saldo data will appear on this page","customClass":"mt-11 p-8","classText":"mt-6 text-sm","classDesc":"mt-2 text-sm text-secondaryText"}})]},proxy:true}])})],1),_c('PaginationNav',{ref:"pagination",attrs:{"page":_vm.parameters.page,"perPage":_vm.parameters.perPage,"totalItem":_vm.controller.paginationData.totalItem},on:{"input":function () {
        _vm.fetchRequestTopupList()
      },"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return (function () {
        _vm.fetchRequestTopupList()
      })($event)}},model:{value:(_vm.parameters.page),callback:function ($$v) {_vm.$set(_vm.parameters, "page", $$v)},expression:"parameters.page"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }