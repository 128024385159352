












































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { REQUEST_TOP_SALDO_PAGINATION, Utils } from '@/app/infrastructures/misc'
import controller, {
  RequestTopupListParams,
} from '@/app/ui/controllers/RequestTopupSaldoController'
import Button from '@/app/ui/components/Button/index.vue'
import DataTable from '@/app/ui/components/DataTable/index.vue'
import EmptyState from '@/app/ui/components/EmptyState/EmptyState.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import ModalUploadBulky from '@/app/ui/views/Adjustment/components/ModalUploadBulky/RequestTopup.vue'
import { EnumStatusUpload } from '@/app/infrastructures/misc/Constants/upload'
import IllustrationCreateFaq from '@/app/ui/assets/ill_create_faq.vue'
import Card from '@/app/ui/components/Card/index.vue'

type fakeDictionary<T> = { [key: string]: T }

@Component({
  components: {
    Card,
    IllustrationCreateFaq,
    ModalUploadBulky,
    TextInput,
    DataTable,
    Button,
    EmptyState,
    LoadingOverlay,
    PaginationNav,
  },
})
export default class RequestTopUpListPage extends Vue {
  controller = controller
  isSuperAdmin = Utils.isSuperAdmin()
  parameters: RequestTopupListParams = {
    page: 1,
    perPage: REQUEST_TOP_SALDO_PAGINATION,
    balanceStatus: 'SHOWALL',
    phoneNumber: '',
    tab: 'list-request',
  }
  headers = ['Customer ID', 'Phone Number', 'Total Saldo']
  showUploadModal = false

  created(): void {
    if (this.$route.query instanceof Object) {
      const queries = this.$route.query as Record<string, never>
      this.parameters = {
        phoneNumber: decodeURIComponent(queries.phoneNumber || ''),
        page: Utils.alwaysNumber(queries.page) || 1,
        perPage: REQUEST_TOP_SALDO_PAGINATION,
        balanceStatus: 'SHOWALL',
        tab: 'list-request',
      }
    }
    this.fetchRequestTopupList()
    controller.setStatusUpload(EnumStatusUpload.START)
  }

  get params(): RequestTopupListParams {
    return {
      ...this.parameters,
      phoneNumber: encodeURIComponent(this.parameters.phoneNumber || ''),
    }
  }

  get dataRequestTopList(): Array<(string | number | undefined)[]> {
    if (this.controller.listRequestTopup) {
      return this.controller.listRequestTopup.map(item => {
        return [
          item.customerId,
          item.phoneNumber ? Utils.countryIndonesiaPhoneNumber(item.phoneNumber, true) : '-',
          Utils.toRupiah(Number(item.balanceAmount)),
        ]
      })
    }
    return []
  }

  private fetchRequestTopupList(resetPage = false) {
    if (resetPage) {
      this.parameters.page = 1
    }
    this.controller.getListRequestTopupSaldo(this.params)
  }

  private actionModalUpload() {
    this.showUploadModal = true
  }

  private onDownloadTemplate(): void {
    window.open(
      'https://storage.googleapis.com/algo-prod/asset/template/TEMPLATE_BULK_TOPUP_BALANCE.xlsx'
    )
  }

  @Watch('params')
  onParamsChanged(val: fakeDictionary<string | (string | null)[] | null | undefined>): void {
    this.$router
      .replace({
        query: {
          ...val,
        },
      })
  }
}
