





























import { Component, Vue, Watch } from 'vue-property-decorator'
import ModalUploadBulky from '@/app/ui/views/Adjustment/components/ModalUploadBulky/main.vue'
import { EnumStatusUpload } from '@/app/infrastructures/misc/Constants/upload'
import controller from '@/app/ui/controllers/RequestTopupSaldoController'
import { EnumErrorUploadBulk } from '@/app/infrastructures/misc/Constants/manualAdjustSaldo'

@Component({
  components: {
    ModalUploadBulky,
  },
})
export default class ModalUploadBulkyRequestTopUp extends Vue {
  showUploadModal = true
  showFailedModal = false
  isDisplayModalBlock = true
  idInputFileName = `input[name="request-topup-upload"]`
  modalFailInfo = {
    showCloseBtn: false,
    title: 'Upload Data Gagal',
    desc: 'Nama file sama, silakan upload ulang file dengan nama yang berbeda',
    icon: 'fail', // or close
  }

  controller = controller
  filename = ''
  status = ''
  enumErrorUploadBulk = EnumErrorUploadBulk

  mounted(): void {
    window.addEventListener('offline', this.onHandleOffline)
  }

  private onHandleOffline(): void {
    if (this.isDisplayModalBlock) {
      this.isDisplayModalBlock = false
      this.showFailedModal = true
      this.modalFailInfo = {
        showCloseBtn: true,
        title: 'Koneksi Internet Bermasalah',
        desc: 'Silahkan upload ulang data Anda',
        icon: 'close',
      }
    }
  }

  @Watch('controller.errUploadBulky')
  onStatusUploadBulky(status: string | null): void {
    if (status) {
      this.showFailedModal = true
      this.isDisplayModalBlock = false
      this.modalFailInfo = {
        showCloseBtn: false,
        title: 'Upload Data Gagal',
        desc: status,
        icon: 'fail',
      }
    }
  }

  @Watch('controller.statusUploadBulky')
  onStatusUploadBulkyCompleted(status: string | null): void {
    if (status === EnumStatusUpload.COMPLETE && !controller.forceStart) {
      this.filename = ''
      if (this.$route.name !== 'RequestTopupPageSaldoPreviewUploadBulk') {
        this.$router
          .replace({ name: 'RequestTopupPageSaldoPreviewUploadBulk' })
          .catch(() => false)
      }
    }
  }

  private actionUploadOnHidden(): void {
    controller.setStatusUpload(EnumStatusUpload.START)
    setTimeout(() => {
      const el: HTMLElement | null = this.$el.querySelector(
        this.idInputFileName
      )
      if (el) {
        el.click()
        this.isDisplayModalBlock = true
      }
      this.showFailedModal = false
    }, 100)
  }

  get isProcessing(): boolean {
    return (
      controller.statusUploadBulky === EnumStatusUpload.PROCESSING &&
      !controller.forceStart
    )
  }

  get isFileInvalid(): boolean {
    return controller.errUploadBulky === this.enumErrorUploadBulk.INVALID_FILE
  }

  private onInputFileBulkUpload(file: File): void {
    controller.setForceStart(false)
    this.isDisplayModalBlock = true
    this.filename = file.name
    controller.uploadBulkyRequestTopUp({ file })
  }

  private closeModalUpload(): void {
    controller.cancelRequest()
    controller.setStatusUpload(EnumStatusUpload.START)
    controller.setForceStart(true)
    this.$emit('close-upload-modal')
    this.showUploadModal = false
  }

  beforeDestroy(): void {
    window.removeEventListener('offline', this.onHandleOffline)
  }
}
