















import { Component, Vue } from 'vue-property-decorator'
import Tabs from '@/app/ui/components/Tabs/index.vue'
import RequestTopUpListPage from '@/app/ui/views/Adjustment/RequestTopup/List/index.vue'
import RequestTopUpHistoryBulkyPage from '@/app/ui/views/Adjustment/RequestTopup/HistoryBulky/index.vue'
import SuccessIcon from '@/app/ui/assets/success_icon_modal.vue'

@Component({
  components: {
    Tabs,
    RequestTopUpListPage,
    RequestTopUpHistoryBulkyPage,
    SuccessIcon,
  },
})
export default class RequestTopUpPage extends Vue {
  itemsTabs = [
    { label: 'List Customer', value: 'list-request' },
    { label: 'History Bulky', value: 'upload-bulky' },
  ]
  currentTab = 'list-request'

  created(): void {
    const queries = this.$route.query as Record<string, never>
    if (queries.tab) {
      this.currentTab = queries.tab
    }
  }

  private onTabsClick(tab: string) {
    this.currentTab = tab
    this.$router.replace({
      query: {},
    })
  }
}
